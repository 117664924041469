import { useState } from "react";
import { Navigate } from 'react-router-dom'
import { useNavigate } from "react-router-dom";



const Home = () => {
   const readToken = localStorage.getItem("token");
   const [token, setToken] = useState(readToken);

   let navigate = useNavigate();

   function logout() {
      //menghapus token dan refresh token pada localstorage
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");

      //unset token
      setToken(null);

      //redirect
      navigate("/login");
   }

   return (
      <>
         <button type="button" onClick={logout}>Logout</button>
      </>
   );
};

export default Home;