import { useState } from "react";
import  { Navigate } from 'react-router-dom'




const Wrapper = (props) => {
   const readToken = localStorage.getItem("token");
   const [token, setToken] = useState(readToken);

   if(token == null){
      return <Navigate to='/login'  />
   }else{
      return <>{ props.page }</>;
   }
};

export default Wrapper;