import { useState, useEffect } from "react";
import { Navigate, Link } from 'react-router-dom'
import GoogleButton from "../Part/GoogleButton";

const Login = () => {
   const readToken = localStorage.getItem("token");
   const [token, setToken] = useState(readToken);
   const [errmsg, setErrmsg] = useState(null);

   const [usernameField, setUsernameField] = useState("");
   const [passwordField, setPasswordField] = useState("");

   //update title
   useEffect(() => {
      document.title = "Textran Login"
   }, []);

   function btnClicked() {
      const tokenString = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJleGFtcGxlLmNvbSIsImlhdCI6MTY4MTQ2NDIzNywiZXhwIjoxNjgxNDY3ODM3LCJ1c2VyIjp7InVzZXJfaWQiOjEyMywidXNlcl9uYW1lIjoiSm9obiBEb2UifX0=.WrS/nuK1KP5PHdrSo6sQNnd/44bFZUrNO4nijVHjpw8=";
      localStorage.setItem("token", tokenString);
      setToken(tokenString);
   }

   function login() {
      //reset error message
      setErrmsg(null);

      if (usernameField == "") {
         setErrmsg("Username harus diisi");
      } else if (passwordField == "") {
         setErrmsg("Password harus diisi");
      } else {
         btnClicked();
      }
   }

   if (token == null) {
      return (
         <>
            <div className="container">
               <div className="box">
                  {/* <div className="loginWrapper">

                     {errmsg != null &&
                        <div className="loginError">
                           {errmsg}
                        </div>
                     }

                     <form>
                        <label for="username">Username</label>
                        <input
                           id="username"
                           type="text"
                           onChange={(evt) => { setUsernameField(evt.target.value); }}
                        />
                        <label for="password">Password</label>
                        <input
                           id="password"
                           type="password"
                           onChange={(evt) => { setPasswordField(evt.target.value); }}
                        />
                        <button type="button" onClick={login} className="loginBtn">Login</button><br />
                        <Link to="/signup">Signup</Link>
                     </form>
                  </div> */}
                  <div className="column signin column-1">
                     <div className="logo">
                     </div>
                     <div className="content">
                        <div className="form">
                           <label className="title unselectable">Sign in to Textran</label>
                           <input type="text" className="username" placeholder="Email or username" />
                           <input type="password" className="password" placeholder="Password" />
                           <div className="forgotPasswordLink">
                              <Link to="/signup" className="unselectable">Forgot your password?</Link>
                           </div>
                           <button className="loginBtn unselectable">SIGN IN</button>
                           <div className="loginDivider unselectable">
                              OR
                           </div>
                           {/* <button className="googleLogin unselectable">Sign in with Google</button> */}
                           <GoogleButton />
                        </div>
                     </div>
                  </div>
                  <div className="column signin column-2">
                     <div className="content">
                        <div className="form">
                           <h1 className="title unselectable">Hallo, Guest!</h1>
                           <h6 className="caption unselectable">If you don't have an account yet, please register first by clicking the following button.</h6>
                           <Link to="/signup">
                              <button className="signupBtn unselectable">SIGN UP</button>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </>
      );
   } else {
      return <Navigate to='/' />
   }
};

export default Login;