import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Wrapper from "./Components/Wrapper";
import Home from "./Components/Pages/Home";
import Login from "./Components/Pages/Login";
import Signup from "./Components/Pages/Signup";
import NoPage from "./Components/Pages/NoPage"

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/">
					<Route index element={<Wrapper page={<Home />} />} />
					<Route path="*" element={<NoPage />} />
				</Route>
				<Route path="login" element={<Login />} />
				<Route path="signup" element={<Signup />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
