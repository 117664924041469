import { useState, useEffect } from "react";
import Config from "../../Config";

const GoogleButton = () => {
   useEffect(() => {
      const script = document.createElement('script');

      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
         document.body.removeChild(script);
      };
   }, []);

   return (
      <>
         <div id="g_id_onload"
            data-client_id={Config.google_client_id}
            data-context="signin"
            data-ux_mode="popup"
            data-callback="callbackGoogle"
            data-itp_support="true">
         </div>

         <div className="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="center"
            data-width="300">
         </div>
      </>
   );
}

export default GoogleButton;