import { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import GoogleButton from "../Part/GoogleButton";

const Signup = () => {
   const readToken = localStorage.getItem("token");
   const [token, setToken] = useState(readToken);
   const [errmsg, setErrmsg] = useState(null);

   const [usernameField, setUsernameField] = useState("");
   const [passwordField, setPasswordField] = useState("");

   //update title
   useEffect(() => {
      document.title = "Textran Sign Up"
   }, []);

   function btnClicked() {
      const tokenString = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJleGFtcGxlLmNvbSIsImlhdCI6MTY4MTQ2NDIzNywiZXhwIjoxNjgxNDY3ODM3LCJ1c2VyIjp7InVzZXJfaWQiOjEyMywidXNlcl9uYW1lIjoiSm9obiBEb2UifX0=.WrS/nuK1KP5PHdrSo6sQNnd/44bFZUrNO4nijVHjpw8=";
      localStorage.setItem("token", tokenString);
      setToken(tokenString);
   }

   function login() {
      //reset error message
      setErrmsg(null);

      if (usernameField == "") {
         setErrmsg("Username harus diisi");
      } else if (passwordField == "") {
         setErrmsg("Password harus diisi");
      } else {
         btnClicked();
      }
   }

   

   if (token == null) {
      return (
         <>
            <div className="container">
               <div className="box">
                  {/* <div className="loginWrapper">

               {errmsg != null &&
                  <div className="loginError">
                     {errmsg}
                  </div>
               }

               <form>
                  <label for="username">Username</label>
                  <input
                     id="username"
                     type="text"
                     onChange={(evt) => { setUsernameField(evt.target.value); }}
                  />
                  <label for="password">Password</label>
                  <input
                     id="password"
                     type="password"
                     onChange={(evt) => { setPasswordField(evt.target.value); }}
                  />
                  <button type="button" onClick={login} className="loginBtn">Daftar</button>
               </form>
               <Link to="/login">Login</Link>
            </div> */}

                  <div className="column signup column-1">
                     <div className="logo">
                     </div>
                     <div className="content">
                        <div className="form">
                           <h1 className="title unselectable">Welcome Back!</h1>
                           <h6 className="caption unselectable">If you already have an account, please log in by clicking the following button.</h6>
                           <Link to="/login">
                              <button className="signinBtn unselectable">SIGN IN</button>
                           </Link>
                        </div>
                     </div>
                  </div>

                  <div className="column signup column-2">
                     <div className="logo">
                     </div>
                     <div className="content">
                        <div className="form">
                           <label className="title unselectable">Create Textran Account</label>
                           <input type="text" className="fullname" placeholder="Full name" />
                           <input type="text" className="username" placeholder="Email or username" />
                           <input type="password" className="password" placeholder="Password" />
                           <button className="signupBtn unselectable">SIGN UP</button>
                           <div className="signupDivider unselectable">
                              OR
                           </div>
                           {/* <button className="googleLogin unselectable">Sign up with Google</button> */}
                           <GoogleButton />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </>
      );
   } else {
      return <Navigate to='/' />
      return <div>udah login</div>
   }
};

export default Signup;